<template>
	<div></div>
</template>

<script>
export default {
	name: 'Blank',
	created() {
		const { deviceId, deviceType } = this.$route.query
		this.$router.replace({
			path: 'detail',
			name: 'deviceDetail',
			query: {
				deviceId,
				deviceType,
			},
		})
	},
}
</script>
